'use client';

import { clx_ } from '@@libs/clx';
import IBtn from '@mui/material/IconButton';
import toast, { Toast as ToastType } from 'react-hot-toast';
import { CgClose } from 'react-icons/cg';
import css from './Toast.module.scss';

/**
 * Types
 */
type ToastProps = {
  data: ToastType;
};

/**
 * Initials
 */
const clx = clx_(css);

/** TODO:
 * implement visualized countback
 */
const Toast = ({ data }: ToastProps) => {
  const { id, type, icon, message, visible } = data;

  // console.log('🚀 ➡️ file: ToastProvider.tsx:10 ➡️ Toast ➡️ data:', data);

  if (!visible) return null;
  return (
    <div className={clx('root')}>
      {/* Icon */}
      {icon && <div className={clx('icon', type)}>{icon}</div>}

      {/* Content */}
      {message instanceof Function ? message(data) : message}

      {/* Close */}
      {type !== 'loading' && (
        <IBtn
          size="small" //
          className={css.close}
          onClick={() => toast.dismiss(id)}
        >
          <CgClose />
        </IBtn>
      )}
    </div>
  );
};
export default Toast;
