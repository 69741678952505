'use client';

import Product from '@@components/pages/products/Product';
import useCart, { CartServiceType, CartTabType } from '@@contexts/cart';
import { Product as ProductType } from '@@graphql/client';
import useAuth from '@@hooks/useAuth';
import { useProductsData } from '@@hooks/useProductsData';
import { clx_ } from '@@libs/clx';
import { Button } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { BiReceipt } from 'react-icons/bi';
import { BsFillCartCheckFill } from 'react-icons/bs';
import { CgSandClock } from 'react-icons/cg';
import { FiShoppingCart } from 'react-icons/fi';
import { SyntheticEvent, useCallback, useEffect } from 'react';
import { usePathname } from 'next/navigation';
import css from './Cart.module.scss';

/**
 * Types
 */

/**
 * Initials
 */
const clx = clx_(css);
const TabList = [
  { icon: CgSandClock, label: 'Függőben', value: 'current' },
  { icon: BiReceipt, label: 'Végleges', value: 'final' },
];
const CartHookSelectorFn =
  (products: ProductType[]) =>
  ({ show, tab, setShow, setTab, getCartItems, resetState, ...rest }: CartServiceType) => ({
    show,
    tab,
    setShow,
    setTab,
    resetState,
    data: rest[tab],
    items: getCartItems(products, tab),
  });

/**
 * Component: Cart
 */
const Cart = () => {
  const path = usePathname() ?? '';
  const { isLoading } = useAuth();
  const { data: products = [] } = useProductsData();
  const { show, tab, setShow, setTab, items, data, resetState } = useCart(CartHookSelectorFn(products));
  const handleTabChange = useCallback((e: SyntheticEvent, v: CartTabType) => setTab(v), [setTab]);
  // const clear = () => { useCart.persist.clearStorage(); resetState(); };

  const cartstate = useCart((s) => s);
  useEffect(() => {
    console.log('🚀 ➡️ Cart ➡️ cartstate:', cartstate);
  }, [cartstate]);

  if (path.startsWith('/admin') || isLoading) return null;

  return (
    <>
      {/* Backdrop */}
      <div
        className={clx('fixed inset-0 z-1 backdrop-blur transition-opacity', { 'pointer-events-none opacity-0': !show })} //md:hidden  bg-purple-600/10
        onClick={() => show && setShow(false)}
      ></div>

      {/* Cart window */}
      <aside className={clx('root', { 'translate-x-[calc(100%_+_0.5rem)] opacity-0': !show })}>
        {/* Asztal */}
        <h1 className="flex cursor-pointer items-center gap-2 leading-none" onClick={resetState}>
          <FiShoppingCart /> Asztal: K1
        </h1>

        {/* Tabs */}
        <Tabs value={tab} onChange={handleTabChange} variant="fullWidth" className="flex-fill">
          {TabList.map(({ icon: Icon, ...tab }) => (
            <Tab key={tab.value} icon={<Icon className="text-2xl" />} iconPosition="start" {...tab} />
          ))}
        </Tabs>

        {/* Lista */}
        <ul className="overflow-y-overlay grow space-y-3 py-1">
          {items.map((item, i) => (
            <Product key={item?.id ?? i} variant="cart" data={item} />
          ))}
        </ul>

        {/* Approve/Cancel button */}
        {tab === 'current' && (
          <Button //
            color="secondary"
            variant="contained"
            className="leading-none"
            // endIcon={<BsFillCartCheckFill />}
            // endIcon={<BsCheckCircleFill />}
          >
            Rendelés beküldése <BsFillCartCheckFill className="mx-2 text-xl" />{' '}
            {data.total.toLocaleString('hu-HU', { style: 'currency', currency: 'HUF', maximumFractionDigits: 0 })}
          </Button>
        )}
      </aside>
    </>
  );
};

export default Cart;
