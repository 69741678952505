import { FaListUl } from 'react-icons/fa';
import { ImUsers } from 'react-icons/im';
import { MdAirlineSeatReclineExtra } from 'react-icons/md';
import { RiReservedFill } from 'react-icons/ri';

/**
 * Types
 */
export type AdminPanelTabType = 'tables' | 'products' | 'users' | 'reservations';

/**
 * Config: Admin page links
 */
export const AdminPageLinks = [
  { icon: MdAirlineSeatReclineExtra, href: '/admin/tables', name: 'Asztalok' },
  { icon: RiReservedFill, href: '/admin/reservations', name: 'Foglalások' },
  { icon: FaListUl, href: '/admin/products', name: 'Termékek' },
  { icon: ImUsers, href: '/admin/users', name: 'Felhasználók' },
];
export const defaultNavigationTab = AdminPageLinks[0].href;
export const TabQueryCallback = (q?: string) => (q ?? defaultNavigationTab) as AdminPanelTabType;
