'use client';

import NavUserButton from '@@components/layout/nav/NavUserButton';
import { AdminPageLinks } from '@@config/page/admin/pages';
import { UserPageLinks } from '@@config/page/user/pages';
import { clx_ } from '@@libs/clx';
import { Button, IconButton, useMediaQuery, useScrollTrigger } from '@mui/material';
import { isDesktop as defaultMatches } from 'react-device-detect';
import { useEffect, useMemo, useRef } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import css from './Nav.module.scss';

// import { FaHome } from 'react-icons/fa';
// import { GrShieldSecurity } from 'react-icons/gr';
// import { MdNavigateNext } from 'react-icons/md';

/**
 * Initials
 */
const clx = clx_(css);
const CartButton = dynamic(() => import('@@components/layout/nav/CartButton'), { ssr: false }); // import CartButton from '@@components/ui/CartButton';
// const NavUserButton = dynamic(() => import('@@components/layout/nav/NavUserButton'), { ssr: false }); // import NavUserButton from '@@components/layout/nav/NavUserButton';

/**
 * Component: Nav
 */
const Nav = () => {
  const path = usePathname() ?? '';
  const navRef = useRef<HTMLElement>(null);
  const isAdminView = path.startsWith('/admin');
  const isHidden = useScrollTrigger({ threshold: 200 });
  const isDesktop = useMediaQuery<any>((theme) => theme.breakpoints.up('sm'), { defaultMatches });
  const links = useMemo(() => (isAdminView ? AdminPageLinks : UserPageLinks), [isAdminView]);

  useEffect(() => void document.body.style.setProperty('--nav-hidden', (+isHidden).toString()), [isHidden]);
  useEffect(() => {
    if (!navRef.current) return;
    const resize_ob = new ResizeObserver(([{ target }]) => document.body.style.setProperty('--nav-height', (target.realSize?.height ?? 82) + 'px'));
    resize_ob.observe(navRef.current);
    return () => void resize_ob.disconnect();
  }, []);

  return (
    <nav ref={navRef} className={clx('root', { slided: isHidden })}>
      {/* Logo */}
      {/* <img className="aspect-square max-h-[2.5rem] object-cover" src={`https://source.unsplash.com/random`} alt="logo" loading="lazy" /> */}

      {/* Pages/Links */}
      <div className="mr-auto flex flex-wrap space-x-3 text-orange-300">
        {links.map(({ icon: Icon, name, href }, i) =>
          isDesktop ? (
            <Button
              key={i}
              href={href}
              variant="text"
              color="inherit"
              aria-label={name}
              startIcon={<Icon />}
              LinkComponent={Link}
              className={clx('btn', 'px-3', { 'btn-active': href === '/' ? path === '/' : path.startsWith(href) })}
            >
              {name}
            </Button>
          ) : (
            <IconButton
              key={i} //
              href={href}
              color="inherit"
              aria-label={name}
              LinkComponent={Link}
              className={clx('btn', { 'btn-active': href === '/' ? path === '/' : path.startsWith(href) })}
            >
              <Icon />
            </IconButton>
          )
        )}
      </div>

      {/* Cart button */}
      {!isAdminView && <CartButton />}

      {/* User */}
      <NavUserButton />

      {/* Breadcrumbs */}
      {/* <Breadcrumbs
        // maxItems={3}
        component="div"
        separator={<MdNavigateNext />}
        className={clx('absolute top-full frame w-fill flex-full rounded-md p-2 text-xs', '-mx-2 sm:mx-3')}
      >
        <MuiLink
          href="/admin"
          color="inherit"
          underline="hover"
          component={Link}
          className="flex items-center gap-1" //
        >
          <FaHome fontSize="1.25em" />
          <GrShieldSecurity css={{ fontSize: '1.25em', marginLeft: '-0.15em', path: { stroke: 'inherit' } }} />
          Admin
        </MuiLink>
        <MuiLink
          href="/admin/tables"
          color="inherit"
          underline="hover"
          component={Link}
          className="flex items-center gap-1" //
        >
          Asztalok
        </MuiLink>
        <Typography color="text.primary" fontSize="inherit">
          K1
        </Typography>
      </Breadcrumbs> */}
    </nav>
  );
};

export default Nav;
