'use client';

import { ReactQueryOptions } from '@@config/react-query.client';
import { HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useState, type PropsWithChildren } from 'react';

// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

/**
 * Types
 */
type ReactQueryProviderProps = PropsWithChildren<{
  // dehydratedState: unknown;
}>;

/**
 * Provider: Tanstack ReactQuery
 */
export default function ReactQueryProvider({
  // dehydratedState,
  children,
}: ReactQueryProviderProps) {
  const [queryClient] = useState(() => new QueryClient(ReactQueryOptions));

  return (
    <QueryClientProvider client={queryClient}>
      <HydrationBoundary
      //state={dehydratedState}
      >
        {children}
      </HydrationBoundary>
      {/* {process.env.NODE_ENV === 'development' && <ReactQueryDevtools buttonPosition="bottom-left" />} */}
    </QueryClientProvider>
  );
}
