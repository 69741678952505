'use client';

import AdvancedIconButton from '@@components/ui/AdvancedIconButton';
import { defaultNavigationTab } from '@@config/page/admin/pages';
import useAuth from '@@hooks/useAuth';
import { clx_ } from '@@libs/clx';
import { isUserAllowed } from '@@libs/next-auth/role.util';
import { Avatar, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import { signIn, signOut } from 'next-auth/react';
import toast from 'react-hot-toast';
import { BiLogOut, BiUser, BiUserCircle, BiUserX } from 'react-icons/bi';
import { FcGoogle } from 'react-icons/fc';
import { GrShieldSecurity } from 'react-icons/gr';
import { MouseEvent, ReactEventHandler, useCallback, useMemo, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import css from './Nav.module.scss';

// import { isDarkLightFeatureEnabled } from '@@config/next-themes';

/**
 * Initials
 */
const clx = clx_(css);
const toastDuration = 3000;

/**
 * Component: NavUserButton
 */
const NavUserButton = () => {
  const pathname = usePathname();
  const onAdminView = pathname.startsWith('/admin');
  const [imgFailed, setimgFailed] = useState(false);
  const { user, isLoading, isFetching, update } = useAuth();
  const { image = '', name, email, role = 'user' } = user ?? {};
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => setAnchorEl(null);
  const onUpdateProfile = () => (handleClose(), update());
  const handleClick = (e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget);
  const onImageError: ReactEventHandler<HTMLImageElement> = useCallback(() => (setimgFailed(true), setTimeout(() => setimgFailed(false), 10000)), []);
  const onLogout = useCallback(async () => {
    handleClose();
    await signOut({ redirect: false });
    toast.success(<div className="min-h-[5rem] p-4">Sikeresen kijelentkeztél.</div>, { icon: <BiLogOut />, duration: toastDuration });
    setTimeout(() => window.location.reload(), toastDuration);
  }, []);

  const UserAvatar = useMemo(
    () => (
      <>
        {image && !imgFailed ? (
          <Image src={image} onError={onImageError} className="rounded-full" alt="user-avatar" priority draggable={false} unselectable="on" unoptimized fill />
        ) : !user && !isLoading ? (
          <BiUserX className={clx('text-orange-300 sm:text-4xl')} />
        ) : (
          <BiUser className={clx('text-orange-300 sm:text-3xl')} />
        )}
        {isFetching && (
          <span className="absolute -inset-0.5 animate-spin rounded-full border-0 border-b-2 border-solid border-b-orange-500 transition-all"></span>
        )}
      </>
    ),
    [imgFailed, image, isFetching, isLoading, onImageError, user]
  );

  return (
    <>
      <AdvancedIconButton
        loading={isFetching}
        disabled={isLoading}
        onClick={handleClick}
        aria-label="nav-user-btn"
        className={clx('UserButton', { 'cursor-wait': isLoading })}
      >
        {UserAvatar}
      </AdvancedIconButton>

      {/* Menu */}
      <Menu
        open={!!anchorEl && !isFetching}
        anchorEl={anchorEl}
        onClose={handleClose}
        slotProps={{ paper: { className: clx('UserMenu') } }} //sx: { mt: 2 }
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        disableScrollLock
      >
        {user && !isLoading ? (
          // User
          <Tooltip title="Profil adatok frissitése" placement="top" arrow>
            <MenuItem key="user" className="mb-4 cursor-progress" onClick={onUpdateProfile}>
              {role === 'user' ? (
                <Avatar className="bg-neutral-600">{UserAvatar}</Avatar>
              ) : (
                <div className="grid gap-1">
                  <div className="z-1 -mb-3 rounded bg-purple-600 px-1 text-center text-xs font-bold uppercase text-purple-100 ring-1 ring-purple-300">
                    {role}
                  </div>
                  <Avatar className="mx-auto bg-neutral-600 ring-2 ring-purple-600">{UserAvatar}</Avatar>
                </div>
              )}
              <ListItemText
                primary={name}
                secondary={email}
                // secondaryTypographyProps={{ component: 'div' }}
              />
            </MenuItem>
          </Tooltip>
        ) : (
          // Login
          <MenuItem onClick={() => signIn('google')}>
            <ListItemIcon>
              {/* <BiLogIn className={clx('svg-path-stroke ml-[-0.25em] !text-[1.75em]')} /> */}
              <FcGoogle className={clx('MenuItemIcon', 'ml-[-0.25em]')} />
            </ListItemIcon>
            <ListItemText primary="Bejelentkezés" />
          </MenuItem>
        )}

        {/* User view */}
        {onAdminView && (
          <MenuItem
            href="/" //
            component={Link}
            onClick={handleClose}
            className={clx('Menu-ChangeView')}
          >
            <ListItemIcon className="text-inherit">
              <BiUserCircle className={clx('MenuItemIcon')} />
            </ListItemIcon>
            <ListItemText primary="Felhasználói nézet" />
          </MenuItem>
        )}

        {/* Admin view */}
        {!onAdminView && isUserAllowed(role, 'employe') && (
          <MenuItem
            component={Link} //
            onClick={handleClose}
            href={defaultNavigationTab}
            className={clx('Menu-ChangeView')}
          >
            <ListItemIcon className="text-inherit">
              <GrShieldSecurity className={clx('MenuItemIcon')} />
            </ListItemIcon>
            <ListItemText primary="Admin nézet" />
          </MenuItem>
        )}

        {/* Logout */}
        {user && [
          <Divider key="divider" />,
          <MenuItem key="logout" className="text-red-800 dark:text-red-400" onClick={onLogout} dense>
            <ListItemIcon className="text-inherit">
              <BiLogOut className={clx('MenuItemIcon', 'ml-[-0.25em] !text-[2em]')} />
            </ListItemIcon>
            <ListItemText primary="Kijelentkezés" />
          </MenuItem>,
        ]}

        {/* Dark/Light */}
        {/* {isDarkLightFeatureEnabled && (
          <MenuItem onClick={() => setTheme(isThemeLight ? 'dark' : 'light')}>
            <ListItemIcon>{isThemeLight ? <BsFillSunFill css={{ fontSize: '1.5rem' }} /> : <BsFillMoonStarsFill css={{ fontSize: '1.5rem' }} />}</ListItemIcon>
            <ListItemText primary={`Kinézet: ${isThemeLight ? 'Világos' : 'Sötét'}`} />
          </MenuItem>
        )} */}
      </Menu>
    </>
  );
};

export default NavUserButton;
