'use client';

import { ProductsCtx } from '@@contexts/products';
import { useProductsQuery, type ProductsQuery } from '@@graphql/client';
import { type UseQueryResult } from '@tanstack/react-query';
import { type PropsWithChildren } from 'react';

/**
 * Types
 */
type ProductsProviderProps = PropsWithChildren;

/**
 * Provider: Products
 */
const ProductsProvider = ({ children }: ProductsProviderProps) => {
  const { data = [], ...rest } = useProductsQuery({}, { select: (data) => data.products });

  return (
    <ProductsCtx.Provider
      value={{
        data,
        ...(rest as Omit<UseQueryResult<ProductsQuery, any>, 'data'>),
      }}
    >
      {children}
    </ProductsCtx.Provider>
  );
};
export default ProductsProvider;
