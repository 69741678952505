// import { LocaleList } from '@@i18n';
import type { Icon } from 'next/dist/lib/metadata/types/metadata-types';
import type { Metadata, Viewport } from 'next/types';

/**
 * Constants
 */
const imageMultiplier = 0.5;
const urlMap = {
  preview: 'https://dev.hashtag-debrecen.hu',
  production: 'https://hashtag-debrecen.hu',
  development: `http://localhost:${process.env.PORT || 3000}`,
};
export const url = urlMap[process.env.NEXT_PUBLIC_VERCEL_ENV ?? 'development'] as string;
const icon: Icon = { url: '/favicon.ico', sizes: '24x24', type: 'image/ico' };
// const icon: Icon = { url: '/icon.png', sizes: '65x24', type: 'image/png' };
export const title = '#Hashtag';
export const description = 'Hashtag Bar & Shisha';

/**
 * Config: Metadata
 */
export const metadata: Metadata = {
  metadataBase: new URL(url),
  title: {
    default: '', //title,
    template: `%s - ${title}`,
  },
  description,
  applicationName: title,
  creator: 'Márk Szőke',
  category: 'Hookah Bar',
  manifest: '/manifest.json',
  icons: { icon, shortcut: icon, apple: icon },
  verification: { google: 'wch9Z7oq6MY75zhKQqXBAe74Ohp9jMsvw3oe8vVOC4I' },
  keywords: ['hookah', 'vizipippa', 'sisha', 'bar', 'Debrecen', 'szórakozás'],
  robots: { index: true, follow: true, nocache: true, googleBot: { index: true, follow: true } },
  // alternates: {
  //   canonical: '/', //   // canonical: '/' + LocaleList[0].value,
  //   //   languages: LocaleList.reduce((obj, { value, hreflang }) => ({ ...obj, [hreflang]: '/' + value }), {
  //   //     [LocaleList[0].hreflang]: '/',
  //   //     'x-default': '/',
  //   //   } as object),
  // },
  openGraph: {
    url,
    title,
    description,
    siteName: title,
    images: [{ alt: title, url: '/og.jpg', type: 'website', width: 2048 * imageMultiplier, height: 1637 * imageMultiplier }],
    type: 'website',
    locale: 'hu-HU',
    countryName: 'Hungary',
    phoneNumbers: '+36308802363',
    emails: 'info@hashtag-debrecen.hu',
  },
  // referrer: 'origin-when-cross-origin',
};

export const viewport: Viewport = { themeColor: 'dark', colorScheme: 'dark', minimumScale: 1, initialScale: 1, width: 'device-width' };
