import { SessionUserType } from '@@config/user.server';

/**
 * Types
 */
type RoleType = SessionUserType['role'];

const roles: RoleType[] = ['admin', 'employe', 'user'];
export const isUserAllowed = (user: RoleType = 'user', allow: RoleType = 'user') => {
  // console.log( '🚀 ➡️ file: UserRestrict.tsx:18 ➡️ isUserAllowed ➡️ user:', [user, allow], [roles.indexOf(user), roles.indexOf(allow)], roles.indexOf(user) <= roles.indexOf(allow) );
  return roles.indexOf(user) <= roles.indexOf(allow);
};
