'use client';

import Toast from '@@components/ui/notification/Toast';
import { Toaster } from 'react-hot-toast';
import { BiErrorCircle } from 'react-icons/bi';
import { FiCheckCircle } from 'react-icons/fi';

/**
 * DEV:
 * https://notistack.com/features/basic
 */

/**
 * Initials
 */
const isDev = process.env.NODE_ENV === 'development';
const defaultDuration = isDev ? Infinity : 10 * 1000; //for DEV

/**
 * Component: ToastProvider
 */
const ToastProvider = () => (
  <Toaster
    position="top-center"
    containerStyle={{ inset: '1.5rem' }}
    toastOptions={{
      duration: defaultDuration,
      success: { icon: <FiCheckCircle /> },
      error: { icon: <BiErrorCircle /> },
    }}
    gutter={8}
  >
    {(data) => <Toast data={data} />}
  </Toaster>
);
export default ToastProvider;
