/* eslint-disable no-unused-vars */
'use strict';

import { groupBy } from '@@libs/utils';

/**
 * Types
 */
type PromiseStatusType = 'pending' | 'resolved' | 'rejected';
export type RealSizeType = { readonly width: number; readonly height: number };
export type PromiseGetStatusFnType = <T extends PromiseStatusType | undefined = undefined>(
  status?: T
) => Promise<T extends undefined ? PromiseStatusType : boolean>;

/**
 * Prototypes
 */
if (typeof window !== 'undefined') {
  Object.defineProperty(Element.prototype, 'realSize', {
    // configurable: true,
    // enumerable: true,
    // writable: true,
    get: function () {
      const computedStyle = window.getComputedStyle(this);
      return {
        width: ['marginLeft', 'marginRight', 'borderLeftWidth', 'borderRightWidth'].reduce(
          (num, item) => (num += parseInt(computedStyle[item], 10)),
          this.clientWidth
        ),
        height: ['marginTop', 'marginBottom', 'borderTopWidth', 'borderBottomWidth'].reduce(
          (num, item) => (num += parseInt(computedStyle[item], 10)),
          this.clientHeight
        ),
      };
    },
    // set: function (v) {
    //   console.log('realSize: set', v, this.realSize);
    //   this.realSize = null;
    // },
  });
}
Array.prototype.groupBy = function (this, fn) {
  return groupBy(this, fn);
};
