'use client';

import { type Session } from 'next-auth';
import { useSession, type UseSessionOptions } from 'next-auth/react';
import { useMemo } from 'react';

/**
 * Types
 */
type UseAuthSelectorType<D = Session['user']> = (data: Session['user']) => D;

/**
 * Hook: useAuth
 */
export default function useAuth<D = Session['user']>(selector?: UseAuthSelectorType<D>, options?: UseSessionOptions<boolean>) {
  const session = useSession(options);
  const isFetching = useMemo(() => session.status === 'loading', [session.status]);
  const isLoading = useMemo(() => !session.data && session.status === 'loading', [session.data, session.status]);
  const user = useMemo(() => {
    if (selector instanceof Function) return session.data ? selector(session.data.user) : undefined;
    return session.data?.user as D extends Session['user'] ? Session['user'] | undefined : D | undefined;
  }, [selector, session.data]);
  return { user, isFetching, isLoading, ...session };
}
