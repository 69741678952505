import clsx, { ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * CLX_: tailwind-merge + clsx + style modules
 * It creates a function with is going to use the css/scss module generated class names,
 * with helps of the 'clsx' library
 */
export const clx_ =
  (css: { readonly [key: string]: string } | undefined = {}) =>
  (...classes: ClassValue[]) =>
    twMerge(
      clsx(...classes)
        .split(' ')
        .map((c) => css[c] || c)
        .join(' ')
    );

/**
 * CLX: tailwind-merge + clsx
 */
export const clx = (...classes: ClassValue[]) => twMerge(clsx(...classes));
export default clx;
