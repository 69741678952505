import { clx_ } from '@@libs/clx';
import { setHexOpacity } from '@@libs/utils';
import { colors } from '@mui/material';
import { createTheme, type ThemeOptions } from '@mui/material/styles';
import { IoIosArrowDown } from 'react-icons/io';
import css from '@@styles/mui.module.scss';

/**
 * Utils
 */
const clx = clx_(css);

/**
 * Mui theme
 */
export const darkTheme: ThemeOptions = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: colors.orange[400],
    },
    secondary: {
      main: colors.purple[600],
    },
    background: {
      default: '#181818',
      paper: '#222222',
    },
  },
  typography: {
    fontFamily: 'var(--font-roboto-sans)',
  },
  // spacing: (factor: number) => `${0.25 * factor}rem`, // (Bootstrap strategy)
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme, ownerState }) => {
          if (ownerState.color == 'primary')
            return `
          & {
            background-color: rgba(255,255,255,0.1);
          }
          &:hover:not(:focus-within) .MuiOutlinedInput-notchedOutline {
            border-color: ${setHexOpacity(theme.palette.primary.main, 0.5)};
          }
          & .MuiOutlinedInput-notchedOutline {
            transition: all 0.4s ease;
            border-color: rgba(255,255,255,0.25);
          }
          &.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-width: 1px !important;
          }

        `;
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        classes: { root: clx('form-control') },
      },
    },
    // MuiFormControlLabel: {
    //   styleOverrides: {
    //     labelPlacementStart: `
    //       margin: 0;
    //     `,
    //   },
    // },

    // MuiTextField: {
    //   // defaultProps: { variant: 'filled', size: 'small' },
    // },

    MuiSelect: {
      defaultProps: {
        classes: { select: clx('select'), icon: clx('select-icon') },
        IconComponent: IoIosArrowDown,
      },
    },
    // MuiLink: {
    //   defaultProps: {
    //     // component: Link as any,
    //   },
    // },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        // LinkComponent: Link,
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        // LinkComponent: Link,
      },
      styleOverrides: {
        root: () => `
          min-width: auto;
        `,
        startIcon: ({ ownerState: { children, onlyIcon } }) => ({
          marginInline: !children || onlyIcon ? 0 : undefined,
          ['&>*:nth-of-type(1)']: { fontSize: '1.5em' },
        }),
        endIcon: ({ ownerState: { children, onlyIcon } }) => ({
          marginInline: !children || onlyIcon ? 0 : undefined,
          ['&>*:nth-of-type(1)']: { fontSize: '1.5em' },
        }),
      },
    },
    MuiSvgIcon: { defaultProps: { fontSize: 'inherit' } },
    MuiMenuItem: {
      styleOverrides: {
        root: `
          gap: 0.5rem;

          & > *:is(.MuiListItemIcon-root) {
            min-width: unset;
          }
        `,
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: `
          min-width: unset;
        `,
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ theme, ownerState: { orientation } }) => {
          if (orientation == 'horizontal')
            return `
        & {
          min-height: 0;
          &:has(.MuiTab-root.Mui-selected:first-child) .MuiTabs-indicator {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }
          &:has(.MuiTab-root.Mui-selected:last-child) .MuiTabs-indicator {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          }
        }

        .MuiTabs-indicator {
          height: 100%;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
          // border: 1px solid ${setHexOpacity(theme.palette.primary.main, 0.25)};
          border-bottom: 2px solid ${setHexOpacity(theme.palette.primary.main)};
          background-color:${setHexOpacity(theme.palette.primary.main, 0.075)};
        }
        .MuiTab-root {
          min-height: 0;
          color: currentColor;
          background-color: rgba(255,255,255,0.1);
          border: 1px solid rgba(255,255,255,0.25);
          transition: color 0.4s ease;

          &:first-of-type {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }
          &:last-of-type {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          }
          & + .MuiTab-root {
            border-left-width: 0;
          }

          &:hover {
            color: ${setHexOpacity(theme.palette.primary.main)};
          }
        }

        ${theme.breakpoints.down('sm')} {
          .MuiTab-root {
            font-size: 0.6rem;
            padding: 0.5rem 0;
            min-width: 60px;
            flex: 1 1 auto;

            & > .MuiTab-iconWrapper {
              margin-right: 0;
            }
          }
        }
      `;
          //     return `
          //   .MuiTabs-indicator {
          //     width: 100%;
          //     border-top-left-radius: 6px;
          //     border-bottom-left-radius: 6px;
          //     // border: 1px solid ${setHexOpacity(theme.palette.primary.main, 0.25)};
          //     border-right: 3px solid ${setHexOpacity(theme.palette.primary.main)};
          //     background-color: rgba(255,255,255,0.1);
          //   }
          //   .MuiTab-root {
          //     color: currentColor;
          //     border-right: 3px solid rgba(255,255,255,0.25);
          //     padding: 0.5rem;
          //     min-height: 0;
          //     transition: color 0.4s ease;
          //     &:hover {
          //       color: ${setHexOpacity(theme.palette.primary.main)};
          //     }
          //   }
          // `;
        },
      },
    },
    MuiPaper: {
      defaultProps: { classes: { root: clx('paper') } },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        slotProps: { tooltip: { className: 'tooltip' }, arrow: { className: clx('tooltip-arrow') } },
      },
      // styleOverrides: {
      //   // arrow: ({ theme }) => ({ color: theme.palette.common.black }),
      //   tooltip: ({ theme }) => ({ fontSize: '0.875rem', isolation: 'isolate' }),
      // },
    },
    MuiDialog: {
      defaultProps: {
        // PaperProps: { component: 'form' },
        slotProps: {
          backdrop: {
            className: 'backdrop-blur',
          },
        },
      },
    },
  },
});

// export const darkTheme: ThemeOptions = createTheme(
//   {
//     palette: {
//       mode: 'dark',
//       primary: {
//         main: colors.orange[400],
//       },
//       secondary: {
//         main: colors.purple[600],
//       },
//       background: {
//         default: '#181818',
//         paper: '#222222',
//       },
//     },
//   },
//   defaultTheme
// );

// export const lightTheme: ThemeOptions = createTheme(
//   {
//     palette: {
//       mode: 'light',
//       primary: {
//         main: colors.orange[600],
//       },
//       secondary: {
//         main: colors.purple[800],
//       },
//     },
//   },
//   defaultTheme
// );

// const MuiThemes: Record<PaletteMode, ThemeOptions> = {
//   light: lightTheme,
//   dark: darkTheme,
// };
// export default MuiThemes;
