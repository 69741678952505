'use client';

import { clx_ } from '@@libs/clx';
import type { ExtendButtonBaseTypeMap } from '@mui/material/ButtonBase';
import IconButton, { type IconButtonTypeMap } from '@mui/material/IconButton';
import type { DefaultComponentProps } from '@mui/material/OverridableComponent';
import type { ClassValue } from 'clsx';
import { forwardRef, ReactNode } from 'react';
import css from './AdvancedIconButton.module.scss';

/**
 * Types
 */
type AdvancedIconButtonProps = Omit<DefaultComponentProps<ExtendButtonBaseTypeMap<IconButtonTypeMap<{}, 'button'>>>, 'className'> & {
  variant?: 'primary' | 'secondary';
  badge?: ReactNode;
  badgePosition?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  loading?: boolean;
  className?: ClassValue;
};

/**
 * Initials
 */
const clx = clx_(css);

/**
 * Component: AdvancedIconButton
 */
export default forwardRef<HTMLButtonElement, AdvancedIconButtonProps>(function AdvancedIconButton(
  { variant = 'primary', badge, badgePosition = 'top-right', loading, className, children, ...props },
  ref
) {
  return (
    <IconButton ref={ref} className={clx('root group', variant, className)} {...props}>
      {children}
      {!!badge && <span className={clx('badge', `badge-position-${badgePosition}`)}>{badge}</span>}
      {loading && <span className={clx('loading-ring')}></span>}
    </IconButton>
  );
});
