import useCart from '@@contexts/cart';
import { Product } from '@@graphql/client';
import { Button, TextField, Typography } from '@mui/material';
import Popover from '@mui/material/Popover';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

/**
 * Types
 */
type EditQuantityProps = {
  data: Product;
  target: HTMLElement | null;
  setTarget: Dispatch<SetStateAction<HTMLElement | null>>;
  initValue?: number;
};

/**
 * Component: EditQuantity
 */
export const EditQuantity = ({ data, target, setTarget, initValue = 1 }: EditQuantityProps) => {
  const { name } = data;
  const [value, setValue] = useState(initValue);
  const setCurrent = useCart(({ setCurrent }) => setCurrent);
  const isChanged = initValue != value;

  useEffect(() => setValue(initValue), [target, initValue]);

  if (!target) return null;
  return (
    <Popover
      open={Boolean(target)}
      anchorEl={target}
      onClose={() => setTarget(null)}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      className="p-2"
    >
      <form
        autoFocus
        className="grid max-w-xs gap-3 rounded-inherit border border-solid border-white/30 p-3"
        onSubmit={(e) => {
          e.preventDefault();
          setCurrent('=', data, value);
          setTarget(null);
        }}
      >
        <Typography sx={{ color: 'white', textAlign: 'center', fontWeight: 600 }}>{name}</Typography>
        <TextField
          value={value}
          type="number"
          size="small"
          variant="outlined"
          className="mx-auto"
          inputProps={{ className: 'text-center' }}
          onChange={(e) => setValue(+e.target.value)}
          autoFocus
        />
        <Button type="submit" variant="contained" disabled={!isChanged}>
          OK
        </Button>
      </form>
    </Popover>
  );
};
