import { url } from '@@config/page/meta';
// import { sleep } from '@@libs/utils';
import request, { type Variables } from 'graphql-request';
import type { GraphQLClientRequestHeaders } from 'graphql-request/build/esm/types';

const isSSR = !global.window; //typeof window !== 'undefined'; // global.window
const baseUrl = isSSR ? `http://localhost:${process.env.PORT || 3000}` : url;

export const gqlFetcher = <TData, TVariables extends Variables>(document: string, variables?: TVariables, headers?: GraphQLClientRequestHeaders) => {
  return async () => {
    // await sleep(10);
    return request<TData>(baseUrl + '/api/graphql', document, variables, headers);
  };
};
// type MyFetcher<TData, TVariables> = (operation: string, variables?: TVariables, options?: RequestInit['headers']) => () => Promise<TData>;
// export const fetchData = <TData, TVariables>(query: string, variables?: TVariables, options?: RequestInit['headers']): (() => Promise<TData>) => {
//   return async () => {
//     const res = await fetch('https://api.url', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         ...options,
//       },
//       body: JSON.stringify({
//         query,
//         variables,
//       }),
//     });

//     const json = await res.json();

//     if (json.errors) {
//       const { message } = json.errors[0] || {};
//       throw new Error(message || 'Error…');
//     }

//     return json.data;
//   };
// };

/* type MyFetcherHook<TData, TVariables> = (operation: string, options?: RequestInit['headers']) => (variables?: TVariables) => Promise<TData>;
export const useFetchData = <TData, TVariables>(query: string, options?: RequestInit['headers']): ((variables?: TVariables) => Promise<TData>) => {
  // it is safe to call React Hooks here.
  const { url, headers } = React.useContext(FetchParamsContext);

  return async (variables?: TVariables) => {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...headers,
        ...options,
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors[0] || {};
      throw new Error(message || 'Error…');
    }

    return json.data;
  };
}; */
