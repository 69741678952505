'use client';

import { ProductsCtx } from '@@contexts/products';
import { Product } from '@@graphql/client';
import { useContext, useMemo } from 'react';

/**
 * Types
 */
type UseProductsDataType<T = Product[]> = (data: Product[]) => T;

/**
 * Hook:
 */
export function useProductsData<T = Product[]>(selector?: UseProductsDataType<T>) {
  const { data, ...rest } = useContext(ProductsCtx);
  const products = useMemo(() => {
    if (selector instanceof Function) return data?.length ? selector(data) : [];
    return data as T extends Product[] ? Product[] : T;
  }, [data, selector]);
  return { products, data, ...rest };
}
