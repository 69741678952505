import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

export const env = createEnv({
  client: {
    NEXT_PUBLIC_GOOGLE_ANALYTICS: z.string().min(1),
    NEXT_PUBLIC_MICROSOFT_CLARITY: z.string().min(1),
  },
  server: {
    /* Google */
    GOOGLE_ID: z.string().min(1),
    GOOGLE_SECRET: z.string().min(1),

    /* AWS */
    PRIVAT_AWS_DEFAULT_REGION: z.string().min(1),
    PRIVAT_AWS_ACCESS_KEY: z.string().min(1),
    PRIVAT_AWS_SECRET_KEY: z.string().min(1),
    PRIVAT_AWS_BUCKET_NAME: z.string().min(1),

    /* Database */
    MONGODB_URI: z.string().min(1),

    /* Auth */
    NEXTAUTH_SECRET: z.string().min(1),
  },
  experimental__runtimeEnv: {
    NEXT_PUBLIC_GOOGLE_ANALYTICS: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS,
    NEXT_PUBLIC_MICROSOFT_CLARITY: process.env.NEXT_PUBLIC_MICROSOFT_CLARITY,
  },
});
export default env;
