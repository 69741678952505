import { useDeleteProductMutation } from '@@graphql/client';
import { clx_ } from '@@libs/clx';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton, { IconButtonOwnProps } from '@mui/material/IconButton';
import { useQueryClient } from '@tanstack/react-query';
import { type GraphQLError } from 'graphql';
import toast from 'react-hot-toast';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import css from '@@components/pages/products/Products.module.scss';

/**
 * Types
 */
type ProductDeleteBtnProps = IconButtonOwnProps & {
  id: string;
  onSuccess?: () => any;
  onError?: () => any;
};

/**
 * Initials
 */
const clx = clx_(css);

/**
 * Component: ProductDeleteBtn
 */
const ProductDeleteBtn = ({ id, onSuccess: onSuccess_, onError: onError_, ...props }: ProductDeleteBtnProps) => {
  const queryClient = useQueryClient();
  const { mutate, isPending } = useDeleteProductMutation<GraphQLError>({
    async onSuccess() {
      toast.success('Törlés sikeres volt.');
      await queryClient.invalidateQueries({ queryKey: ['Products'] });
      onSuccess_?.();
    },
    onError(error) {
      console.error('delete error:', error);
      toast.error('Törlés sikertelen volt.');
      onError_?.();
      return error;
    },
  });
  const onDeleteClick = () => confirm('Biztos törölni akarod?') && mutate({ id });

  return (
    <IconButton
      className={clx('action-btn-delete')} //
      size="small"
      color="error"
      onClick={onDeleteClick}
      disabled={isPending}
      {...props}
    >
      {isPending ? <CircularProgress size="1em" className="text-red-400" /> : <RiDeleteBin2Fill className="" />}
    </IconButton>
  );
};

export default ProductDeleteBtn;
