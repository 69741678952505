'use client';

import { zustand_useSelectors } from '@@libs/zustand';
import { create } from 'zustand';
import { useEffect } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import EventEmitter from 'events';

/**
 * Types
 */
type NavigationEventCtxType = {
  isLoading: boolean;
  url: string;
  setUrl: (url: string, finished?: boolean) => any;
};

/**
 * Initials
 */
export const NavigationEvent = new EventEmitter();

/**
 * Contexts/Hooks
 */
export const useNavigationEvent = zustand_useSelectors(
  create<NavigationEventCtxType>()((set) => ({ url: '', isLoading: false, setUrl: (url, finished = false) => set({ url, isLoading: !finished }) }))
);

export default function NavigationEvents() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { url: navigationUrl, isLoading, setUrl } = useNavigationEvent();

  useEffect(() => {
    const url = `${pathname}?${searchParams}`;
    isLoading && url.includes(navigationUrl) && setUrl(url, true);
    // console.log('NavigationEvents', url, navigationUrl, isLoading);
  }, [isLoading, navigationUrl, pathname, searchParams, setUrl]);

  return null;
  // return <div className="pointer-events-none fixed inset-0 grid place-items-center bg-sky-400/50 text-5xl">{isLoading.toString()}</div>;
}

// // Create the event.
// const event = document.createEvent("Event");

// // Define that the event name is 'build'.
// event.initEvent("build", true, true);

// // Listen for the event.
// elem.addEventListener(
//   "build",
//   (e) => {
//     // e.target matches elem
//   },
//   false,
// );

// // target can be any Element or other EventTarget.
// elem.dispatchEvent(event);

// var event = new Event('my-event');

// // Listen for the event.
// elem.addEventListener('my-event', function (e) { /* ... */ }, false);

// // Dispatch the event.
// elem.dispatchEvent(event);
