import { Product as ProductModel, Receipt as ReceiptModel, Table as TableModel, User as UserModel } from '@prisma/client/index.d';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions, useSuspenseQuery, UseSuspenseQueryOptions } from '@tanstack/react-query';
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { GraphQLContextType } from 'src/graphql/context';
import { gqlFetcher } from 'src/libs/graphql.fetcher';

export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  AnyType: { input: any; output: any };
  Date: { input: any; output: any };
};

export enum Category {
  Drink = 'drink',
  Pipa = 'pipa',
  Snack = 'snack',
}

export type EditProductInput = {
  available?: InputMaybe<Scalars['Boolean']['input']>;
  category?: InputMaybe<Category>;
  details?: InputMaybe<Scalars['String']['input']>;
  img?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type EditReceiptInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  paid?: InputMaybe<Scalars['Boolean']['input']>;
  table?: InputMaybe<Scalars['String']['input']>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EditTableInput = {
  capacity?: InputMaybe<Scalars['Int']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  receiptId?: InputMaybe<Scalars['String']['input']>;
  reserved?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Mutation = {
  addUserToReceipt: Scalars['Boolean']['output'];
  addUsersToReceipt: Scalars['Boolean']['output'];
  delUserFromReceipt: Scalars['Boolean']['output'];
  delUsersFromReceipt: Scalars['Boolean']['output'];
  deleteProduct: Scalars['Boolean']['output'];
  deleteReceipt: Scalars['Boolean']['output'];
  deleteTable: Scalars['Boolean']['output'];
  editProduct?: Maybe<Product>;
  editReceipt: Receipt;
  editTable?: Maybe<Table>;
  newProduct?: Maybe<Product>;
  newReceipt?: Maybe<Receipt>;
  newTable?: Maybe<Table>;
  sendProductsToReceipt?: Maybe<Scalars['AnyType']['output']>;
};

export type MutationAddUserToReceiptArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationAddUsersToReceiptArgs = {
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['String']['input'];
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MutationDelUserFromReceiptArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationDelUsersFromReceiptArgs = {
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['String']['input'];
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MutationDeleteProductArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type MutationDeleteReceiptArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationDeleteTableArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationEditProductArgs = {
  data: EditProductInput;
  id: Scalars['String']['input'];
};

export type MutationEditReceiptArgs = {
  data: EditReceiptInput;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationEditTableArgs = {
  data: EditTableInput;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationNewProductArgs = {
  data: NewProductInput;
};

export type MutationNewReceiptArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  table?: InputMaybe<Scalars['String']['input']>;
  userIds: Array<Scalars['String']['input']>;
};

export type MutationNewTableArgs = {
  data: NewTableInput;
};

export type MutationSendProductsToReceiptArgs = {
  data: SendProductsToReceiptInput;
};

export type NewProductInput = {
  available: Scalars['Boolean']['input'];
  category: Category;
  details?: InputMaybe<Scalars['String']['input']>;
  img?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  price: Scalars['Int']['input'];
  type: Scalars['String']['input'];
};

export type NewTableInput = {
  capacity?: InputMaybe<Scalars['Int']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  receiptId?: InputMaybe<Scalars['String']['input']>;
};

export type Node = {
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type Product = Node & {
  available: Scalars['Boolean']['output'];
  category: Category;
  createdAt: Scalars['Date']['output'];
  details?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  img?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type Query = {
  activeReceipt?: Maybe<Receipt>;
  getMyReceipts: Array<Maybe<Receipt>>;
  product?: Maybe<Product>;
  products: Array<Product>;
  receipt?: Maybe<Receipt>;
  receipts: Array<Receipt>;
  table?: Maybe<Table>;
  tables: Array<Table>;
  user?: Maybe<User>;
  users: Array<User>;
};

export type QueryProductArgs = {
  id: Scalars['String']['input'];
};

export type QueryProductsArgs = {
  available?: InputMaybe<Scalars['Boolean']['input']>;
  category?: InputMaybe<Category>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryReceiptArgs = {
  id: Scalars['String']['input'];
};

export type QueryReceiptsArgs = {
  filter?: InputMaybe<ReceiptFilterInput>;
};

export type QueryTableArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryTablesArgs = {
  filter?: InputMaybe<TablesQueryFilter>;
};

export type QueryUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type QueryUsersArgs = {
  role?: InputMaybe<Role>;
};

export type Receipt = Node & {
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  paid: Scalars['Boolean']['output'];
  products?: Maybe<Array<Maybe<ReceiptItem>>>;
  table?: Maybe<Scalars['String']['output']>;
  tableName?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  userIds: Array<Scalars['String']['output']>;
  users: Array<User>;
};

export type ReceiptFilterInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  paid?: InputMaybe<Scalars['Boolean']['input']>;
  tableId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type ReceiptItem = {
  productId: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
};

export type ReceiptItemInput = {
  productId: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
};

export enum Role {
  Admin = 'admin',
  Employe = 'employe',
  User = 'user',
}

export type SendProductsToReceiptInput = {
  products: Array<ReceiptItemInput>;
  receiptId: Scalars['String']['input'];
};

export type Table = Node & {
  capacity: Scalars['Int']['output'];
  createdAt: Scalars['Date']['output'];
  hidden: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  receipt?: Maybe<Receipt>;
  receiptId?: Maybe<Scalars['String']['output']>;
  reserved: Scalars['Boolean']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type TablesQueryFilter = {
  assigned?: InputMaybe<Scalars['Boolean']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reserved?: InputMaybe<Scalars['Boolean']['input']>;
};

export type User = Node & {
  createdAt: Scalars['Date']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  role: Role;
  updatedAt: Scalars['Date']['output'];
};

export type ProductsQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  available?: InputMaybe<Scalars['Boolean']['input']>;
  category?: InputMaybe<Category>;
}>;

export type ProductsQuery = {
  products: Array<{
    id: string;
    name: string;
    details?: string | null | undefined;
    price: number;
    category: Category;
    type: string;
    img?: string | null | undefined;
    available: boolean;
    createdAt: any;
    updatedAt: any;
  }>;
};

export type ProductQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type ProductQuery = {
  product?:
    | {
        id: string;
        name: string;
        details?: string | null | undefined;
        price: number;
        category: Category;
        type: string;
        img?: string | null | undefined;
        available: boolean;
        createdAt: any;
        updatedAt: any;
      }
    | null
    | undefined;
};

export type NewProductMutationVariables = Exact<{
  data: NewProductInput;
}>;

export type NewProductMutation = {
  newProduct?:
    | {
        id: string;
        name: string;
        details?: string | null | undefined;
        price: number;
        category: Category;
        type: string;
        img?: string | null | undefined;
        available: boolean;
        createdAt: any;
        updatedAt: any;
      }
    | null
    | undefined;
};

export type EditProductMutationVariables = Exact<{
  id: Scalars['String']['input'];
  data: EditProductInput;
}>;

export type EditProductMutation = {
  editProduct?:
    | {
        id: string;
        name: string;
        details?: string | null | undefined;
        price: number;
        category: Category;
        type: string;
        img?: string | null | undefined;
        available: boolean;
        createdAt: any;
        updatedAt: any;
      }
    | null
    | undefined;
};

export type DeleteProductMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;

export type DeleteProductMutation = { deleteProduct: boolean };

export type TablesQueryVariables = Exact<{
  filter?: InputMaybe<TablesQueryFilter>;
}>;

export type TablesQuery = {
  tables: Array<{
    id: string;
    name: string;
    hidden: boolean;
    capacity: number;
    reserved: boolean;
    receiptId?: string | null | undefined;
    updatedAt: any;
    createdAt: any;
    receipt?:
      | {
          id: string;
          name?: string | null | undefined;
          paid: boolean;
          userIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          users: Array<{ id: string; role: Role; name: string; email: string; image?: string | null | undefined; createdAt: any; updatedAt: any }>;
          products?: Array<{ productId: string; quantity: number } | null | undefined> | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type TableQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
}>;

export type TableQuery = {
  table?:
    | {
        id: string;
        name: string;
        hidden: boolean;
        capacity: number;
        reserved: boolean;
        receiptId?: string | null | undefined;
        updatedAt: any;
        createdAt: any;
        receipt?: { userIds: Array<string>; name?: string | null | undefined } | null | undefined;
      }
    | null
    | undefined;
};

export type NewTableMutationVariables = Exact<{
  data: NewTableInput;
}>;

export type NewTableMutation = {
  newTable?:
    | { id: string; name: string; hidden: boolean; capacity: number; reserved: boolean; receiptId?: string | null | undefined; updatedAt: any; createdAt: any }
    | null
    | undefined;
};

export type UsersQueryVariables = Exact<{
  role?: InputMaybe<Role>;
}>;

export type UsersQuery = {
  users: Array<{ id: string; name: string; role: Role; email: string; image?: string | null | undefined; createdAt: any; updatedAt: any }>;
};

export type UserQueryVariables = Exact<{
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
}>;

export type UserQuery = {
  user?: { id: string; name: string; role: Role; email: string; image?: string | null | undefined; createdAt: any; updatedAt: any } | null | undefined;
};

export type ProductFieldsFragment = {
  id: string;
  name: string;
  details?: string | null | undefined;
  price: number;
  category: Category;
  type: string;
  img?: string | null | undefined;
  available: boolean;
  createdAt: any;
  updatedAt: any;
};

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping of interface types */
export type ResolversInterfaceTypes<RefType extends Record<string, unknown>> = ResolversObject<{
  Node: ProductModel | ReceiptModel | TableModel | UserModel;
}>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  AnyType: ResolverTypeWrapper<Partial<Scalars['AnyType']['output']>>;
  Boolean: ResolverTypeWrapper<Partial<Scalars['Boolean']['output']>>;
  Category: ResolverTypeWrapper<Partial<Category>>;
  Date: ResolverTypeWrapper<Partial<Scalars['Date']['output']>>;
  EditProductInput: ResolverTypeWrapper<Partial<EditProductInput>>;
  EditReceiptInput: ResolverTypeWrapper<Partial<EditReceiptInput>>;
  EditTableInput: ResolverTypeWrapper<Partial<EditTableInput>>;
  Int: ResolverTypeWrapper<Partial<Scalars['Int']['output']>>;
  Mutation: ResolverTypeWrapper<{}>;
  NewProductInput: ResolverTypeWrapper<Partial<NewProductInput>>;
  NewTableInput: ResolverTypeWrapper<Partial<NewTableInput>>;
  Node: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['Node']>;
  Product: ResolverTypeWrapper<ProductModel>;
  Query: ResolverTypeWrapper<{}>;
  Receipt: ResolverTypeWrapper<ReceiptModel>;
  ReceiptFilterInput: ResolverTypeWrapper<Partial<ReceiptFilterInput>>;
  ReceiptItem: ResolverTypeWrapper<Partial<ReceiptItem>>;
  ReceiptItemInput: ResolverTypeWrapper<Partial<ReceiptItemInput>>;
  Role: ResolverTypeWrapper<Partial<Role>>;
  SendProductsToReceiptInput: ResolverTypeWrapper<Partial<SendProductsToReceiptInput>>;
  String: ResolverTypeWrapper<Partial<Scalars['String']['output']>>;
  Table: ResolverTypeWrapper<TableModel>;
  TablesQueryFilter: ResolverTypeWrapper<Partial<TablesQueryFilter>>;
  User: ResolverTypeWrapper<UserModel>;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  AnyType: Partial<Scalars['AnyType']['output']>;
  Boolean: Partial<Scalars['Boolean']['output']>;
  Date: Partial<Scalars['Date']['output']>;
  EditProductInput: Partial<EditProductInput>;
  EditReceiptInput: Partial<EditReceiptInput>;
  EditTableInput: Partial<EditTableInput>;
  Int: Partial<Scalars['Int']['output']>;
  Mutation: {};
  NewProductInput: Partial<NewProductInput>;
  NewTableInput: Partial<NewTableInput>;
  Node: ResolversInterfaceTypes<ResolversParentTypes>['Node'];
  Product: ProductModel;
  Query: {};
  Receipt: ReceiptModel;
  ReceiptFilterInput: Partial<ReceiptFilterInput>;
  ReceiptItem: Partial<ReceiptItem>;
  ReceiptItemInput: Partial<ReceiptItemInput>;
  SendProductsToReceiptInput: Partial<SendProductsToReceiptInput>;
  String: Partial<Scalars['String']['output']>;
  Table: TableModel;
  TablesQueryFilter: Partial<TablesQueryFilter>;
  User: UserModel;
}>;

export type AuthDirectiveArgs = {
  requires?: Maybe<Role>;
};

export type AuthDirectiveResolver<Result, Parent, ContextType = GraphQLContextType, Args = AuthDirectiveArgs> = DirectiveResolverFn<
  Result,
  Parent,
  ContextType,
  Args
>;

export interface AnyTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AnyType'], any> {
  name: 'AnyType';
}

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export type MutationResolvers<
  ContextType = GraphQLContextType,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation'],
> = ResolversObject<{
  addUserToReceipt?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationAddUserToReceiptArgs, 'id'>>;
  addUsersToReceipt?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationAddUsersToReceiptArgs, 'id'>>;
  delUserFromReceipt?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDelUserFromReceiptArgs, 'id'>>;
  delUsersFromReceipt?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDelUsersFromReceiptArgs, 'id'>>;
  deleteProduct?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, Partial<MutationDeleteProductArgs>>;
  deleteReceipt?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, Partial<MutationDeleteReceiptArgs>>;
  deleteTable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, Partial<MutationDeleteTableArgs>>;
  editProduct?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType, RequireFields<MutationEditProductArgs, 'data' | 'id'>>;
  editReceipt?: Resolver<ResolversTypes['Receipt'], ParentType, ContextType, RequireFields<MutationEditReceiptArgs, 'data'>>;
  editTable?: Resolver<Maybe<ResolversTypes['Table']>, ParentType, ContextType, RequireFields<MutationEditTableArgs, 'data'>>;
  newProduct?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType, RequireFields<MutationNewProductArgs, 'data'>>;
  newReceipt?: Resolver<Maybe<ResolversTypes['Receipt']>, ParentType, ContextType, RequireFields<MutationNewReceiptArgs, 'userIds'>>;
  newTable?: Resolver<Maybe<ResolversTypes['Table']>, ParentType, ContextType, RequireFields<MutationNewTableArgs, 'data'>>;
  sendProductsToReceipt?: Resolver<Maybe<ResolversTypes['AnyType']>, ParentType, ContextType, RequireFields<MutationSendProductsToReceiptArgs, 'data'>>;
}>;

export type NodeResolvers<ContextType = GraphQLContextType, ParentType extends ResolversParentTypes['Node'] = ResolversParentTypes['Node']> = ResolversObject<{
  __resolveType: TypeResolveFn<'Product' | 'Receipt' | 'Table' | 'User', ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
}>;

export type ProductResolvers<
  ContextType = GraphQLContextType,
  ParentType extends ResolversParentTypes['Product'] = ResolversParentTypes['Product'],
> = ResolversObject<{
  available?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  category?: Resolver<ResolversTypes['Category'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  details?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  img?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QueryResolvers<
  ContextType = GraphQLContextType,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query'],
> = ResolversObject<{
  activeReceipt?: Resolver<Maybe<ResolversTypes['Receipt']>, ParentType, ContextType>;
  getMyReceipts?: Resolver<Array<Maybe<ResolversTypes['Receipt']>>, ParentType, ContextType>;
  product?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType, RequireFields<QueryProductArgs, 'id'>>;
  products?: Resolver<Array<ResolversTypes['Product']>, ParentType, ContextType, Partial<QueryProductsArgs>>;
  receipt?: Resolver<Maybe<ResolversTypes['Receipt']>, ParentType, ContextType, RequireFields<QueryReceiptArgs, 'id'>>;
  receipts?: Resolver<Array<ResolversTypes['Receipt']>, ParentType, ContextType, Partial<QueryReceiptsArgs>>;
  table?: Resolver<Maybe<ResolversTypes['Table']>, ParentType, ContextType, Partial<QueryTableArgs>>;
  tables?: Resolver<Array<ResolversTypes['Table']>, ParentType, ContextType, Partial<QueryTablesArgs>>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, Partial<QueryUserArgs>>;
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType, Partial<QueryUsersArgs>>;
}>;

export type ReceiptResolvers<
  ContextType = GraphQLContextType,
  ParentType extends ResolversParentTypes['Receipt'] = ResolversParentTypes['Receipt'],
> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  products?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReceiptItem']>>>, ParentType, ContextType>;
  table?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tableName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  userIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReceiptItemResolvers<
  ContextType = GraphQLContextType,
  ParentType extends ResolversParentTypes['ReceiptItem'] = ResolversParentTypes['ReceiptItem'],
> = ResolversObject<{
  productId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TableResolvers<
  ContextType = GraphQLContextType,
  ParentType extends ResolversParentTypes['Table'] = ResolversParentTypes['Table'],
> = ResolversObject<{
  capacity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  receipt?: Resolver<Maybe<ResolversTypes['Receipt']>, ParentType, ContextType>;
  receiptId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reserved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserResolvers<ContextType = GraphQLContextType, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['Role'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = GraphQLContextType> = ResolversObject<{
  AnyType?: GraphQLScalarType;
  Date?: GraphQLScalarType;
  Mutation?: MutationResolvers<ContextType>;
  Node?: NodeResolvers<ContextType>;
  Product?: ProductResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Receipt?: ReceiptResolvers<ContextType>;
  ReceiptItem?: ReceiptItemResolvers<ContextType>;
  Table?: TableResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
}>;

export type DirectiveResolvers<ContextType = GraphQLContextType> = ResolversObject<{
  auth?: AuthDirectiveResolver<any, any, ContextType>;
}>;

export const ProductFieldsFragmentDoc = `
    fragment ProductFields on Product {
  id
  name
  details
  price
  category
  type
  img
  available
  createdAt
  updatedAt
}
    `;
export const ProductsDocument = `
    query Products($name: String, $available: Boolean, $category: Category) {
  products(name: $name, available: $available, category: $category) {
    ...ProductFields
  }
}
    ${ProductFieldsFragmentDoc}`;

export const useProductsQuery = <TData = ProductsQuery, TError = Error>(
  variables?: ProductsQueryVariables,
  options?: Omit<UseQueryOptions<ProductsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<ProductsQuery, TError, TData>['queryKey'] }
) => {
  return useQuery<ProductsQuery, TError, TData>({
    queryKey: variables === undefined ? ['Products'] : ['Products', variables],
    queryFn: gqlFetcher<ProductsQuery, ProductsQueryVariables>(ProductsDocument, variables),
    ...options,
  });
};

useProductsQuery.getKey = (variables?: ProductsQueryVariables) => (variables === undefined ? ['Products'] : ['Products', variables]);

export const useSuspenseProductsQuery = <TData = ProductsQuery, TError = Error>(
  variables?: ProductsQueryVariables,
  options?: Omit<UseSuspenseQueryOptions<ProductsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseSuspenseQueryOptions<ProductsQuery, TError, TData>['queryKey'];
  }
) => {
  return useSuspenseQuery<ProductsQuery, TError, TData>({
    queryKey: variables === undefined ? ['ProductsSuspense'] : ['ProductsSuspense', variables],
    queryFn: gqlFetcher<ProductsQuery, ProductsQueryVariables>(ProductsDocument, variables),
    ...options,
  });
};

useSuspenseProductsQuery.getKey = (variables?: ProductsQueryVariables) => (variables === undefined ? ['ProductsSuspense'] : ['ProductsSuspense', variables]);

useProductsQuery.fetcher = (variables?: ProductsQueryVariables, options?: RequestInit['headers']) =>
  gqlFetcher<ProductsQuery, ProductsQueryVariables>(ProductsDocument, variables, options);

export const ProductDocument = `
    query Product($id: String!) {
  product(id: $id) {
    ...ProductFields
  }
}
    ${ProductFieldsFragmentDoc}`;

export const useProductQuery = <TData = ProductQuery, TError = Error>(
  variables: ProductQueryVariables,
  options?: Omit<UseQueryOptions<ProductQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<ProductQuery, TError, TData>['queryKey'] }
) => {
  return useQuery<ProductQuery, TError, TData>({
    queryKey: ['Product', variables],
    queryFn: gqlFetcher<ProductQuery, ProductQueryVariables>(ProductDocument, variables),
    ...options,
  });
};

useProductQuery.getKey = (variables: ProductQueryVariables) => ['Product', variables];

export const useSuspenseProductQuery = <TData = ProductQuery, TError = Error>(
  variables: ProductQueryVariables,
  options?: Omit<UseSuspenseQueryOptions<ProductQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseSuspenseQueryOptions<ProductQuery, TError, TData>['queryKey'];
  }
) => {
  return useSuspenseQuery<ProductQuery, TError, TData>({
    queryKey: ['ProductSuspense', variables],
    queryFn: gqlFetcher<ProductQuery, ProductQueryVariables>(ProductDocument, variables),
    ...options,
  });
};

useSuspenseProductQuery.getKey = (variables: ProductQueryVariables) => ['ProductSuspense', variables];

useProductQuery.fetcher = (variables: ProductQueryVariables, options?: RequestInit['headers']) =>
  gqlFetcher<ProductQuery, ProductQueryVariables>(ProductDocument, variables, options);

export const NewProductDocument = `
    mutation NewProduct($data: NewProductInput!) {
  newProduct(data: $data) {
    ...ProductFields
  }
}
    ${ProductFieldsFragmentDoc}`;

export const useNewProductMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<NewProductMutation, TError, NewProductMutationVariables, TContext>
) => {
  return useMutation<NewProductMutation, TError, NewProductMutationVariables, TContext>({
    mutationKey: ['NewProduct'],
    mutationFn: (variables?: NewProductMutationVariables) => gqlFetcher<NewProductMutation, NewProductMutationVariables>(NewProductDocument, variables)(),
    ...options,
  });
};

useNewProductMutation.fetcher = (variables: NewProductMutationVariables, options?: RequestInit['headers']) =>
  gqlFetcher<NewProductMutation, NewProductMutationVariables>(NewProductDocument, variables, options);

export const EditProductDocument = `
    mutation EditProduct($id: String!, $data: EditProductInput!) {
  editProduct(id: $id, data: $data) {
    ...ProductFields
  }
}
    ${ProductFieldsFragmentDoc}`;

export const useEditProductMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<EditProductMutation, TError, EditProductMutationVariables, TContext>
) => {
  return useMutation<EditProductMutation, TError, EditProductMutationVariables, TContext>({
    mutationKey: ['EditProduct'],
    mutationFn: (variables?: EditProductMutationVariables) => gqlFetcher<EditProductMutation, EditProductMutationVariables>(EditProductDocument, variables)(),
    ...options,
  });
};

useEditProductMutation.fetcher = (variables: EditProductMutationVariables, options?: RequestInit['headers']) =>
  gqlFetcher<EditProductMutation, EditProductMutationVariables>(EditProductDocument, variables, options);

export const DeleteProductDocument = `
    mutation DeleteProduct($id: String) {
  deleteProduct(id: $id)
}
    `;

export const useDeleteProductMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<DeleteProductMutation, TError, DeleteProductMutationVariables, TContext>
) => {
  return useMutation<DeleteProductMutation, TError, DeleteProductMutationVariables, TContext>({
    mutationKey: ['DeleteProduct'],
    mutationFn: (variables?: DeleteProductMutationVariables) =>
      gqlFetcher<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, variables)(),
    ...options,
  });
};

useDeleteProductMutation.fetcher = (variables?: DeleteProductMutationVariables, options?: RequestInit['headers']) =>
  gqlFetcher<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, variables, options);

export const TablesDocument = `
    query Tables($filter: TablesQueryFilter) {
  tables(filter: $filter) {
    id
    name
    hidden
    capacity
    reserved
    receiptId
    updatedAt
    createdAt
    receipt {
      id
      name
      paid
      userIds
      users {
        id
        role
        name
        email
        image
        createdAt
        updatedAt
      }
      products {
        productId
        quantity
      }
      createdAt
      updatedAt
    }
  }
}
    `;

export const useTablesQuery = <TData = TablesQuery, TError = Error>(
  variables?: TablesQueryVariables,
  options?: Omit<UseQueryOptions<TablesQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<TablesQuery, TError, TData>['queryKey'] }
) => {
  return useQuery<TablesQuery, TError, TData>({
    queryKey: variables === undefined ? ['Tables'] : ['Tables', variables],
    queryFn: gqlFetcher<TablesQuery, TablesQueryVariables>(TablesDocument, variables),
    ...options,
  });
};

useTablesQuery.getKey = (variables?: TablesQueryVariables) => (variables === undefined ? ['Tables'] : ['Tables', variables]);

export const useSuspenseTablesQuery = <TData = TablesQuery, TError = Error>(
  variables?: TablesQueryVariables,
  options?: Omit<UseSuspenseQueryOptions<TablesQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseSuspenseQueryOptions<TablesQuery, TError, TData>['queryKey'];
  }
) => {
  return useSuspenseQuery<TablesQuery, TError, TData>({
    queryKey: variables === undefined ? ['TablesSuspense'] : ['TablesSuspense', variables],
    queryFn: gqlFetcher<TablesQuery, TablesQueryVariables>(TablesDocument, variables),
    ...options,
  });
};

useSuspenseTablesQuery.getKey = (variables?: TablesQueryVariables) => (variables === undefined ? ['TablesSuspense'] : ['TablesSuspense', variables]);

useTablesQuery.fetcher = (variables?: TablesQueryVariables, options?: RequestInit['headers']) =>
  gqlFetcher<TablesQuery, TablesQueryVariables>(TablesDocument, variables, options);

export const TableDocument = `
    query Table($name: String, $id: String) {
  table(name: $name, id: $id) {
    id
    name
    hidden
    capacity
    reserved
    receiptId
    receipt {
      userIds
      name
    }
    updatedAt
    createdAt
  }
}
    `;

export const useTableQuery = <TData = TableQuery, TError = Error>(
  variables?: TableQueryVariables,
  options?: Omit<UseQueryOptions<TableQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<TableQuery, TError, TData>['queryKey'] }
) => {
  return useQuery<TableQuery, TError, TData>({
    queryKey: variables === undefined ? ['Table'] : ['Table', variables],
    queryFn: gqlFetcher<TableQuery, TableQueryVariables>(TableDocument, variables),
    ...options,
  });
};

useTableQuery.getKey = (variables?: TableQueryVariables) => (variables === undefined ? ['Table'] : ['Table', variables]);

export const useSuspenseTableQuery = <TData = TableQuery, TError = Error>(
  variables?: TableQueryVariables,
  options?: Omit<UseSuspenseQueryOptions<TableQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<TableQuery, TError, TData>['queryKey'] }
) => {
  return useSuspenseQuery<TableQuery, TError, TData>({
    queryKey: variables === undefined ? ['TableSuspense'] : ['TableSuspense', variables],
    queryFn: gqlFetcher<TableQuery, TableQueryVariables>(TableDocument, variables),
    ...options,
  });
};

useSuspenseTableQuery.getKey = (variables?: TableQueryVariables) => (variables === undefined ? ['TableSuspense'] : ['TableSuspense', variables]);

useTableQuery.fetcher = (variables?: TableQueryVariables, options?: RequestInit['headers']) =>
  gqlFetcher<TableQuery, TableQueryVariables>(TableDocument, variables, options);

export const NewTableDocument = `
    mutation NewTable($data: NewTableInput!) {
  newTable(data: $data) {
    id
    name
    hidden
    capacity
    reserved
    receiptId
    updatedAt
    createdAt
  }
}
    `;

export const useNewTableMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<NewTableMutation, TError, NewTableMutationVariables, TContext>
) => {
  return useMutation<NewTableMutation, TError, NewTableMutationVariables, TContext>({
    mutationKey: ['NewTable'],
    mutationFn: (variables?: NewTableMutationVariables) => gqlFetcher<NewTableMutation, NewTableMutationVariables>(NewTableDocument, variables)(),
    ...options,
  });
};

useNewTableMutation.fetcher = (variables: NewTableMutationVariables, options?: RequestInit['headers']) =>
  gqlFetcher<NewTableMutation, NewTableMutationVariables>(NewTableDocument, variables, options);

export const UsersDocument = `
    query Users($role: Role) {
  users(role: $role) {
    id
    name
    role
    email
    image
    createdAt
    updatedAt
  }
}
    `;

export const useUsersQuery = <TData = UsersQuery, TError = Error>(
  variables?: UsersQueryVariables,
  options?: Omit<UseQueryOptions<UsersQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<UsersQuery, TError, TData>['queryKey'] }
) => {
  return useQuery<UsersQuery, TError, TData>({
    queryKey: variables === undefined ? ['Users'] : ['Users', variables],
    queryFn: gqlFetcher<UsersQuery, UsersQueryVariables>(UsersDocument, variables),
    ...options,
  });
};

useUsersQuery.getKey = (variables?: UsersQueryVariables) => (variables === undefined ? ['Users'] : ['Users', variables]);

export const useSuspenseUsersQuery = <TData = UsersQuery, TError = Error>(
  variables?: UsersQueryVariables,
  options?: Omit<UseSuspenseQueryOptions<UsersQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<UsersQuery, TError, TData>['queryKey'] }
) => {
  return useSuspenseQuery<UsersQuery, TError, TData>({
    queryKey: variables === undefined ? ['UsersSuspense'] : ['UsersSuspense', variables],
    queryFn: gqlFetcher<UsersQuery, UsersQueryVariables>(UsersDocument, variables),
    ...options,
  });
};

useSuspenseUsersQuery.getKey = (variables?: UsersQueryVariables) => (variables === undefined ? ['UsersSuspense'] : ['UsersSuspense', variables]);

useUsersQuery.fetcher = (variables?: UsersQueryVariables, options?: RequestInit['headers']) =>
  gqlFetcher<UsersQuery, UsersQueryVariables>(UsersDocument, variables, options);

export const UserDocument = `
    query User($email: String, $id: String) {
  user(email: $email, id: $id) {
    id
    name
    role
    email
    image
    createdAt
    updatedAt
  }
}
    `;

export const useUserQuery = <TData = UserQuery, TError = Error>(
  variables?: UserQueryVariables,
  options?: Omit<UseQueryOptions<UserQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<UserQuery, TError, TData>['queryKey'] }
) => {
  return useQuery<UserQuery, TError, TData>({
    queryKey: variables === undefined ? ['User'] : ['User', variables],
    queryFn: gqlFetcher<UserQuery, UserQueryVariables>(UserDocument, variables),
    ...options,
  });
};

useUserQuery.getKey = (variables?: UserQueryVariables) => (variables === undefined ? ['User'] : ['User', variables]);

export const useSuspenseUserQuery = <TData = UserQuery, TError = Error>(
  variables?: UserQueryVariables,
  options?: Omit<UseSuspenseQueryOptions<UserQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<UserQuery, TError, TData>['queryKey'] }
) => {
  return useSuspenseQuery<UserQuery, TError, TData>({
    queryKey: variables === undefined ? ['UserSuspense'] : ['UserSuspense', variables],
    queryFn: gqlFetcher<UserQuery, UserQueryVariables>(UserDocument, variables),
    ...options,
  });
};

useSuspenseUserQuery.getKey = (variables?: UserQueryVariables) => (variables === undefined ? ['UserSuspense'] : ['UserSuspense', variables]);

useUserQuery.fetcher = (variables?: UserQueryVariables, options?: RequestInit['headers']) =>
  gqlFetcher<UserQuery, UserQueryVariables>(UserDocument, variables, options);
