import { ProductCategoryMap, ProductCategoryMapType, ProductMapType, ProductTypeMap } from '@@config/products';
import { Product as ProductType } from '@@graphql/client';
import { clx_ } from '@@libs/clx';
import css from './Products.module.scss';

/**
 * Types
 */
type ProductBadgeProps = Pick<ProductType, 'category' | 'type'>;

/**
 * Initials
 */
const clx = clx_(css);

/**
 * Component: ProductBadge
 */
export const ProductBadge = ({ category, type }: ProductBadgeProps) => {
  const {
    label: CategoryLabel = category,
    icon: ProductCategoryIcon,
    colorSet = 'orange',
  } = ProductCategoryMap.find((item) => item.value == category) ?? ({} as ProductCategoryMapType);
  const { label: TypeLabel = type } = ProductTypeMap[category].find((item) => item.value === type) ?? ({} as ProductMapType);

  return (
    <div className={clx('relative isolate flex rounded-md text-xs', `badge-${colorSet}-bg`)}>
      <div className={clx('z-1 flex items-center rounded-md bg-black/10 px-1.5 py-0.5', `badge-${colorSet}-mainBorder`)}>
        {ProductCategoryIcon && <ProductCategoryIcon className="text-[1.5em]" />}
        <span className="ml-1.5 hidden sm:block">{CategoryLabel}</span>
      </div>
      <div className={clx('-ml-3 rounded-r-md py-1 pl-4 pr-2 ', `badge-${colorSet}-subBorder`)}>{TypeLabel}</div>
    </div>
  );
};
