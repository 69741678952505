import { Product, ProductsQuery } from '@@graphql/client';
import { UseQueryResult } from '@tanstack/react-query';
import { createContext } from 'react';

/**
 * Types
 */
export type ProductsCtxType = Omit<UseQueryResult<ProductsQuery, any>, 'data'> & {
  data: Product[];
};

/**
 * Context: ProductsCtx
 */
export const ProductsCtx = createContext({} as ProductsCtxType);
