'use client';

import { clx_ } from '@@libs/clx';
import Fab from '@mui/material/Fab';
import { FaArrowUp } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import css from './ScrollToTop.module.scss';

/**
 * Initials
 */
const clx = clx_(css);

/**
 * Utility: ScrollToTop
 */
const ScrollToTop = () => {
  const [Show, setShow] = useState(false);

  useEffect(() => {
    const fn = () => setShow(document.documentElement.scrollTop >= document.documentElement.clientHeight * 0.6);
    // setShow(document.documentElement.scrollTop / (document.documentElement.scrollHeight - document.documentElement.clientHeight));

    window.addEventListener('scroll', fn);
    return () => window.removeEventListener('scroll', fn);
  }, []);

  return (
    <Fab
      size="small" //
      aria-label="scroll-to-top"
      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      className={clx('root', { hidden: !Show })}
    >
      <FaArrowUp className="text-2xl" />
    </Fab>
  );
};

export default ScrollToTop;
