import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/layout.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/libs/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter-sans\"}],\"variableName\":\"fontInter\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/libs/fonts.ts\",\"import\":\"Roboto\",\"arguments\":[{\"display\":\"swap\",\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\",\"500\",\"700\"],\"variable\":\"--font-roboto-sans\"}],\"variableName\":\"fontRoboto\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/libs/fonts.ts\",\"import\":\"JetBrains_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-jetbrains-mono\"}],\"variableName\":\"fontMono\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/layout/ClientPrototypes.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/layout/nav/Cart.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/layout/nav/Nav.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/providers/MuiThemeProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/providers/NextAuthProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/providers/ProductsProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/providers/ReactQueryProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/providers/ThemeProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/notification/ToastProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/utils/MicrosoftClarity.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/utils/NavigationEvents.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/utils/ScrollToTop.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/index.scss")