'use client';

import { SessionProvider, SessionProviderProps } from 'next-auth/react';

/**
 * Provider: NextAuthProvider
 */
export default function NextAuthProvider({ session, children, ...props }: SessionProviderProps) {
  return (
    <SessionProvider session={session} refetchInterval={30} refetchOnWindowFocus {...props}>
      {children}
    </SessionProvider>
  );
}
