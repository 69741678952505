import { ThemeProviderProps } from 'next-themes/dist/types';

export const isDarkLightFeatureEnabled = false;

const NextThemesConfig: ThemeProviderProps = {
  themes: ['light', 'dark'],
  defaultTheme: 'dark', // 'system',
  attribute: 'class', // 'data-theme'
  // forcedTheme: 'light', //DEV: dark one not done, use this for now
  // enableSystem: false,
  // storageKey: 'theme',
  disableTransitionOnChange: true,
};
export default NextThemesConfig;
