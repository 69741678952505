'use client';

import { darkTheme } from '@@config/mui/theme';
import createCache, { type Options } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
// import { Options as SheetOptions, StyleSheet } from '@emotion/sheet';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MaterialUIThemeProvider } from '@mui/material/styles';
import { ReactNode, useMemo } from 'react';
import { useServerInsertedHTML } from 'next/navigation';

/**
 * Types
 */
type MuiThemeProviderProps = {
  options?: Options; // Omit<Options, 'insertionPoint'>;
  children: ReactNode;
};

/**
 * Initials
 */

/**
 * Component: MuiThemeProvider
 */
export default function MuiThemeProvider({ options = {} as Options, children }: MuiThemeProviderProps) {
  const registry = useMemo(() => {
    options.key ??= 'mui';
    options.prepend = true;
    const cache = createCache(options);
    cache.compat = true;
    const prevInsert = cache.insert;
    let inserted: { name: string; isGlobal: boolean }[] = [];
    cache.insert = (...args) => {
      const [selector, serialized] = args;
      if (cache.inserted[serialized.name] === undefined) {
        inserted.push({
          name: serialized.name,
          isGlobal: !selector,
        });
      }
      return prevInsert(...args);
    };
    const flush = () => {
      const prevInserted = inserted;
      inserted = [];
      return prevInserted;
    };
    return { cache, flush };
  }, [options]);

  useServerInsertedHTML(() => {
    const inserted = registry.flush();
    if (inserted.length === 0) return null;
    let styles = '';
    let dataEmotionAttribute = registry.cache.key;
    const globals: { name: string; style: string }[] = [];
    inserted.forEach(({ name, isGlobal }) => {
      const style = registry.cache.inserted[name];
      if (typeof style !== 'boolean') {
        if (isGlobal) globals.push({ name, style });
        else {
          styles += style;
          dataEmotionAttribute += ` ${name}`;
        }
      }
    });

    return (
      <>
        {globals.map(({ name, style }) => (
          <style
            key={name}
            data-emotion={`${registry.cache.key}-global ${name}`}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: style }}
          />
        ))}
        {styles && (
          <style
            data-emotion={dataEmotionAttribute}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: styles }}
          />
        )}
      </>
    );
  });

  // const { theme = 'dark' } = useTheme();
  const themeData = useMemo(() => darkTheme /*  (theme == 'dark' ? darkTheme : lightTheme) */, []); // const themeData = useMemo(() => createTheme(MuiThemes[theme as PaletteMode]), [theme]);

  return (
    <CacheProvider value={registry.cache}>
      <MaterialUIThemeProvider theme={themeData}>
        <CssBaseline classes={{}} />
        {children}
      </MaterialUIThemeProvider>
    </CacheProvider>
  );
}

// const createEmotionCache = (options: Options) => {
//   const cache = createCache(options);

//   /**
//    * A custom styleSheet is used here because emotion's Global component creates a separate styleSheet,
//    * making some of the cache's options ineffective, like 'prepend' and 'insertionPoint',
//    * details: https://github.com/emotion-js/emotion/issues/2790
//    */
//   class MyStyleSheet extends StyleSheet {
//     constructor(options?: SheetOptions) {
//       super(options);
//       // hack emotion's Global new styleSheet
//       // @ts-ignore
//       this.prepend = cache.sheet.prepend;
//       // @ts-ignore
//       this.insertionPoint = cache.sheet.insertionPoint;
//     }
//   }

//   const isBrowser = typeof document !== 'undefined';
//   let container: Node;
//   if (isBrowser) {
//     container = options.container || document.head;
//   }
//   // @ts-ignore
//   cache.sheet = new MyStyleSheet({
//     key: options.key,
//     // @ts-ignore
//     container: container,
//     nonce: options.nonce,
//     speedy: options.speedy,
//     prepend: options.prepend,
//     insertionPoint: options.insertionPoint,
//   });

//   return cache;
// };
