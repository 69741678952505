'use client';

/* eslint-disable no-unused-vars */
import { Product } from '@@graphql/client';
import { zustand_LocalStorage, zustand_useSelectors } from '@@libs/zustand';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

/**
 * Types
 */
export type CartServiceType = {
  show: boolean;
  tab: CartTabType;
  final: {
    list: Record<string, CartListType>;
    total: number;
  };
  current: {
    list: Record<string, CartListType>;
    total: number;
  };
  setShow: (value?: boolean | ((v: boolean) => boolean)) => void;
  setTab: (tab?: CartTabType) => void;
  setCurrent: SetCurrentFn;
  resetState: () => void;

  getCartItemQuantity: GetCartItemQuantityFn;
  getCartItems: GetCartItemFn;
};

export type CartTabType = 'current' | 'final';
type CartListType = { price: number; count: number };
type SetCurrentActionType = '+' | '-' | '=' | 'x';
type SetCurrentFn = (action: SetCurrentActionType, item: Product, value?: number) => void;
type GetCartItemQuantityFn = (id: string) => number;
type GetCartItemFn = (products: Product[], type?: CartTabType) => Product[];

/** Initials */
export const initialState: Pick<CartServiceType, 'show' | 'tab' | 'current' | 'final'> = {
  show: false,
  tab: 'current',
  final: {
    list: {},
    total: 0,
  },
  current: {
    list: {},
    total: 0,
  },
};

/**
 * Contexts/Hooks
 */
const useCart = zustand_useSelectors(
  create<CartServiceType>()(
    persist(
      (set, get) => ({
        resetState: () => set(initialState),
        ...initialState,
        setShow: (value) => set(({ show }) => ({ show: typeof value == 'undefined' ? !show : value instanceof Function ? value(show) : value })),
        setTab: (tab = initialState.tab) => set({ tab }),
        setCurrent: (action, item, value = 1) => set((state) => ({ current: setCurrentReducer(action, item, value, state) })),

        getCartItemQuantity: (id) => get().current.list[id]?.count ?? 0,
        getCartItems: (products, type = initialState.tab) => {
          const list = get()[type].list;
          return Object.entries(list).map(([id]) => products.find((product) => product.id == id) as Product);
        },
      }),
      {
        name: 'cart',
        storage: createJSONStorage(() => zustand_LocalStorage),
      }
    )
  )
);
export default useCart;

const setCurrentReducer = (action: SetCurrentActionType, { id, price }: Product, value: number, state: CartServiceType) => {
  const { list } = state.current;
  let newCurrent: typeof list = {};

  switch (action) {
    case '=':
      newCurrent = { ...list, [id]: { price, count: value } };
      break;
    case '+': {
      const newValue = (list[id]?.count ?? 0) + value;
      newCurrent = { ...list, [id]: { price, count: newValue } };
      break;
    }
    case '-': {
      const newValue = (list[id]?.count ?? 0) - value;
      newCurrent = { ...list, [id]: { price, count: newValue } };
      break;
    }
    case 'x': {
      delete list[id];
      newCurrent = list;
      break;
    }
    default:
  }

  for (const item in newCurrent) newCurrent[item].count <= 0 && delete newCurrent[item];
  // const sum = Object.entries(newCurrent).reduce((num, [id, value]) => (num += (Products.find(({ id: itemId }) => itemId == id)?.price ?? 0) * value), 0);
  const sum = Object.entries(newCurrent).reduce((num, [, value]) => (num += value.price * value.count), 0);
  return { list: newCurrent, total: sum };
};
