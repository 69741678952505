'use client';

import '@@libs/prototype';

/**
 * Types
 */
type ClientPrototypesProps = {
  //n
};

/**
 * Component: ClientPrototypes
 */
export default function ClientPrototypes(props: ClientPrototypesProps) {
  return null;
}
