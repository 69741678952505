'use client';

/* eslint-disable react-hooks/rules-of-hooks */
import noImg from '@@assets/imgs/no-img.svg';
import ProductDeleteBtn from '@@components/pages/admin/products/ProductDeleteBtn';
import type { ProductType } from '@@config/products';
import useCart from '@@contexts/cart';
import { clx_ } from '@@libs/clx';
import { Button, Skeleton, Tooltip, useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { BiMinus, BiPlus } from 'react-icons/bi';
import { FiEdit } from 'react-icons/fi';
import { MdDeleteForever } from 'react-icons/md';
import { useState } from 'react';
import Link from 'next/link';
import { EditQuantity } from './EditQuantity';
import { ProductBadge } from './ProductBadge';
import css from './Products.module.scss';

/**
 * TODO list
 * - make user view splitted to 2 on desktops
 * - set default image which shows when the product doesn't have img
 */

/**
 * Types
 */
type ProductVariant_loading = { variant: 'loading'; data?: never };
type ProductVariant_default = { variant?: 'normal' | 'cart' | 'admin'; data: ProductType };
export type ProductProps = ProductVariant_default | ProductVariant_loading;

/**
 * Initials
 */
const clx = clx_(css);

/**
 * Component: Product
 */
const Product = ({ data, variant = 'normal' }: ProductProps) => {
  if (variant === 'loading') return <ProductLoading />;

  if (!data) return null;
  const { id, category, type, name, price = 0, details, img, available } = data;

  const [target, setTarget] = useState<HTMLElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => setTarget(event.currentTarget);

  const isDesktop = useMediaQuery<any>((theme) => theme.breakpoints.up('sm')); //Required<ThemeOptions>
  const { setCurrent, quantity } = useCart(({ setCurrent, getCartItemQuantity }) => ({ setCurrent, quantity: getCartItemQuantity(id) }));

  return (
    <li className={clx('root')}>
      {/* Header */}
      <div className={clx('header')}>
        {/* Category / Type */}
        <ProductBadge category={category} type={type} />

        {/* Title */}
        <h4 className="grow truncate">
          {variant == 'admin' && !available && '❌'}
          {name}
        </h4>

        {/* Price */}
        <div className="ml-2 font-bold">
          {(variant == 'cart' ? price * quantity : price).toLocaleString('hu-HU', { style: 'currency', currency: 'HUF', maximumFractionDigits: 0 })}
        </div>
      </div>

      {/* Image */}
      <img className={clx('image')} src={img || noImg.src} alt={id} loading="lazy" />

      {/* Details */}
      <div className={clx('details')}>{details}</div>

      {/* Buttons */}
      <div className={clx('actions')}>
        {variant != 'admin' && (
          <>
            {!!quantity && (
              <>
                {/* Delete */}
                <Tooltip title="Töröl" placement="bottom" arrow>
                  <IconButton size="small" color="error" className={clx('action-btn-delete', 'mr-1')} onClick={() => setCurrent('x', data)}>
                    <MdDeleteForever className="" />
                  </IconButton>
                </Tooltip>

                {/* Minus */}
                {quantity > 1 && (
                  <Tooltip title="Elvesz" placement="bottom" arrow>
                    <IconButton size="small" color="primary" className={clx('action-btn')} onClick={() => setCurrent('-', data)}>
                      <BiMinus className="" />
                    </IconButton>
                  </Tooltip>
                )}

                {/* Edit/Manual */}
                <Tooltip title="Darabszám módosítása" placement="bottom" arrow>
                  <IconButton size="small" color="primary" className={clx('action-btn', 'px-3 text-xs')} onClick={handleClick}>
                    {quantity}
                  </IconButton>
                </Tooltip>
                <EditQuantity data={data} target={target} setTarget={setTarget} initValue={quantity} />
              </>
            )}

            {/* Plus */}
            <Tooltip title="Hozzáad" placement="bottom" arrow>
              <IconButton size="small" color="primary" className={clx('action-btn')} onClick={() => setCurrent('+', data)}>
                <BiPlus className="" />
              </IconButton>
            </Tooltip>
          </>
        )}

        {variant == 'admin' && (
          <>
            {/* @ts-expect-error */}
            <Button
              className="" //
              size="small"
              color="warning"
              scroll={false}
              variant="outlined"
              LinkComponent={Link}
              href={`/admin/product/${id}`}
              startIcon={<FiEdit className="" />}
            >
              {isDesktop && 'Szerkeszt'}
            </Button>
            <ProductDeleteBtn id={id} />
          </>
        )}
      </div>
    </li>
  );
};
export default Product;

/**
 * Component: ProductLoading
 */
const ProductLoading = () => {
  return (
    <li className={clx('root')}>
      {/* Header */}
      <div className={clx('header')}>
        {/* Category / Type */}
        <Skeleton variant="rounded" width="15ch" height="1.5em" />

        {/* Title */}
        <h4 className="grow truncate">
          <Skeleton variant="rounded" className="max-w-[50ch]" height="1.5em" />
        </h4>

        {/* Price */}
        <div className="ml-2 font-bold">
          <Skeleton variant="rounded" width="10ch" height="1.5em" />
        </div>
      </div>

      {/* Image */}
      <Skeleton variant="rounded" width={'3.5rem'} height={'100%'} />

      {/* Details */}
      <div className={clx('details')}>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </div>

      {/* Buttons */}
      <div className={clx('actions', '!m-0')}>
        <Skeleton variant="rounded" width={180} height={40} />
      </div>
    </li>
  );
};
