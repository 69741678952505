import type { Product } from '@@graphql/client';
import { IconType } from 'react-icons';
import { BiDrink } from 'react-icons/bi';
import { BsJoystick } from 'react-icons/bs';
import { GiChipsBag } from 'react-icons/gi';

/**
 * Types
 */
export type ProductType = Product;
export type ProductCategoryType = 'pipa' | 'drink' | 'snack';
export type ProductMapType = { label: string; value: string };
export type ProductCategoryMapType = ProductMapType & { icon: IconType; colorSet?: string };

/**
 * Consts
 */
export const PipasTypeMap: ProductMapType[] = [
  { label: 'Normál', value: 'normal' },
  { label: 'Prémium', value: 'premium' },
];
export const SnacksTypeMap: ProductMapType[] = [
  { label: 'Chips', value: 'chips' }, //
];
export const DrinksTypeMap: ProductMapType[] = [
  { label: 'Alkohol', value: 'alcohol' },
  // Normal
  { label: 'Tea', value: 'tea' },
  { label: 'Kávé', value: 'coffee' },
  { label: 'Üditő', value: 'normal' },
  { label: 'My aloe', value: 'myaloe' },
  { label: 'Limonádé', value: 'lemonade' },
  { label: 'Energia ital', value: 'energy' },
  // Alcohol
  { label: 'Sör', value: 'beer' },
  { label: 'Bor', value: 'wine' },
  { label: 'Rum', value: 'rum' },
  { label: 'Gin', value: 'gin' },
  { label: 'Rozé', value: 'rose' },
  { label: 'Cider', value: 'cider' },
  { label: 'Likőr', value: 'liqueur' },
  { label: 'Vodka', value: 'vodka' },
  { label: 'Konyak', value: 'brandy' },
  { label: 'Whisky', value: 'whisky' },
  { label: 'Pezsgő', value: 'champagne' },
  { label: 'Pálinka', value: 'palinka' },
  { label: 'Tequila', value: 'tequila' },
];
export const ProductTypeMap = {
  pipa: PipasTypeMap,
  drink: DrinksTypeMap,
  snack: SnacksTypeMap,
};
export const ProductCategoryMap: ProductCategoryMapType[] = [
  { label: 'Vizipipák', value: 'pipa', icon: BsJoystick, colorSet: 'orange' },
  { label: 'Italok', value: 'drink', icon: BiDrink, colorSet: 'blue' },
  { label: 'Snacks', value: 'snack', icon: GiChipsBag, colorSet: 'purple' },
];

/**
 * Configs
 */
export const defaultProductImagePath = '/uploads/product_imgs';
export const ProductsPageDefaultCategory: ProductCategoryType = 'pipa';
