import { StoreApi, UseBoundStore } from 'zustand';
import { StateStorage } from 'zustand/middleware';

/**
 * Types
 */
type WithSelectors<S> = S extends { getState: () => infer T } ? S & { use: { [K in keyof T]: () => T[K] } } : never;

/**
 * Utility: createSelectors
 * @example const prop = useCart.use.property()
 */
export const zustand_useSelectors = <S extends UseBoundStore<StoreApi<object>>>(_store: S) => {
  const store = _store as WithSelectors<typeof _store>;
  store.use = {};
  for (const k of Object.keys(store.getState())) {
    (store.use as any)[k] = () => store((s) => s[k as keyof typeof s]);
  }
  return store;
};

/**
 * Storage
 */
export const zustand_LocalStorage: StateStorage = {
  getItem: async (name) =>
    new Promise((res) => {
      setTimeout(() => {
        if (typeof localStorage === 'undefined') res(null);
        else setTimeout(() => res(localStorage.getItem(name)), 100);
      });
    }),
  setItem: async (name, value) => localStorage.setItem(name, value),
  removeItem: async (name) => localStorage.removeItem(name),
};
